<template>
  <v-overlay v-if="dialog" z-index="10000">
    <v-card light v-if="dialog" class="overlayCard">
      <div class="overlayHeader pa-2"><v-icon @click="dialog = false" dark>mdi-close</v-icon></div>
      <div class="overlayContent pa-2">
        <component v-bind:is="currentOverlay" />
      </div>
    </v-card>
  </v-overlay>
</template>

<script>
/**
 * Overlay Content Show
 */
export default {
  components: {
    currentOverlay: null
  },
  data: () => ({
    dialog: false
  }),
  props: {
    html: {
      type: String,
      default: () => ''
    },
    switch: {
      type: Boolean,
      default: () => false
    }
  },
  watch: {
    switch() {
      this.dialog = true;
    },
    html(value) {
      if (value === 'imprint') {
        this.currentOverlay = () => import(/* webpackChunkName: "imprint" */ '@/components/misc/imprint.vue');
      } else if (value === 'terms') {
        this.currentOverlay = () => import(/* webpackChunkName: "terms" */ '@/components/misc/terms.vue');
      } else if (value === 'privacy') {
        this.currentOverlay = () => import(/* webpackChunkName: "terms" */ '@/components/misc/privacy.vue');
      } else if (value === 'contactEcoplus') {
        this.currentOverlay = () =>
          import(/* webpackChunkName: "contactEcoplus" */ '@/components/misc/contactEcoplus.vue');
      }
    },
    dialog(value) {
      if (this.html === 'contactEcoplus' && !value) {
        // if the user closes the "contact ecoplus"-popup, set a cookie
        // stating that they have seen the ecoplus contact already, not showing it again
        const date = new Date();
        date.setTime(date.getTime() + 30 * 24 * 60 * 60 * 1000 * 12); // 360 days
        const expires = '; expires=' + date.toUTCString();
        document.cookie = 'contactShown=true' + expires + '; path=/';
      }
    }
  },
  methods: {}
};
</script>

<style scoped>
.overlayCard {
  width: calc(100vw - 60px);
  max-width: 1200px;
  height: calc((var(--vh, 1vh) * 100) - 80px);
}
@media only screen and (max-width: 900px) {
  .overlayCard {
    width: calc(100vw - 10px);
    height: calc((var(--vh, 1vh) * 100) - 20px);
  }
}

.overlayContent {
  height: calc((var(--vh, 1vh) * 100) - 120px);
  overflow: auto;
}

.overlayHeader {
  text-align: right;
  background-color: rgb(0, 115, 181);
}
</style>
