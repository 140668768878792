<template>
  <div v-if="kampagne" class="whiteBack">
    <v-row no-gutters v-for="item in reihenfolge" :key="item" class="modulRow">
      <v-col cols="12" class="zindex1" :class="{ 'mt-10': modus != 'single' }" v-if="modulanzeigen(item, 'V')">
        <v-card class="moduleContent veranstaltung" v-html="modulText(item)" />
      </v-col>

      <v-col cols="12" class="zindex1" :class="{ 'mt-10': modus != 'single' }" v-if="modulanzeigen(item, 'N')">
        <v-card class="moduleContent news" v-html="modulText(item)" />
      </v-col>

      <v-col cols="12" class="zindex1" :class="{ 'mt-10': modus != 'single' }" v-if="modulanzeigen(item, 'D')">
        <v-card class="moduleContent downloads">
          <h1>Downloads:</h1>
          <div v-for="download in kampagne.poolpdf" :key="download">
            <a :href="`${s3Base}/dokumente/${download}`" target="_blank"
              ><v-icon class="mr-3" large>mdi-file-pdf-box</v-icon
              ><span>{{ download.substr(0, download.length - 37) }}</span></a
            >
          </div>
        </v-card>
      </v-col>

      <div
        v-if="hasVideo(item) && modulanzeigen(item, 'M')"
        class="responsiveHeight"
        :class="{ max250: maxHeight == 250, 'mt-10': modus != 'single' }"
        v-html="modulText(item)"
        style="width: 100%"
      />

      <!-- Anzeigen Modul Nur Bild -->
      <v-img
        v-if="modulanzeigen(item, 'M') && imgOnly(item) && modus == 'single'"
        width="100%"
        position="center center"
        :src="imgurl(item)"
        class="responsiveHeight"
        :class="{ max250: maxHeight == 250 }"
      />
      <v-img
        v-if="modulanzeigen(item, 'M') && imgOnly(item) && modus != 'single'"
        width="100%"
        height="auto"
        :src="imgurl(item)"
        class="mt-10"
      />

      <v-col
        v-if="modulanzeigen(item, 'M') && imgLeft(item)"
        :cols="imgWidth(item)"
        :class="{ 'mt-10': modus != 'single' }"
      >
        <v-img
          height="100%"
          :class="{ responsiveHeight: modus == 'single', max250: maxHeight == 250 }"
          position="center center"
          :src="imgurl(item)"
        />
      </v-col>
      <v-col
        v-if="txtControls(item) && modulanzeigen(item, 'M')"
        :class="{
          'px-5 posRelative noOverflow': modus == 'single',
          'mt-10': modus != 'single',
          'pl-5': modus != 'single' && imgLeft(item),
          'pr-5': modus != 'single' && imgRight(item)
        }"
        class="moduleContent"
      >
        <div
          v-html="modulText(item)"
          :class="{
            'px-5 responsiveHeight': modus == 'single',
            max250: maxHeight == 250,
            'pa-5': modus == 'single' && !imgLeft(item) && !imgRight(item)
          }"
        />
        <div v-if="modus == 'single'" class="textWhiteout" />
      </v-col>
      <v-col
        v-if="modulanzeigen(item, 'M') && imgRight(item)"
        :cols="imgWidth(item)"
        :class="{ 'mt-10': modus != 'single' }"
      >
        <v-img
          height="100%"
          :class="{ responsiveHeight: modus == 'single', max250: maxHeight == 250 }"
          position="center center"
          :src="imgurl(item)"
        />
      </v-col>

      <!-- Anzeigen der Karte -->
      <v-col
        :cols="imgLeft(item) ? imgWidth(item) : 12"
        class="zindex1"
        :class="{ 'mt-10': modus != 'single' }"
        v-if="modulanzeigen(item, 'K') && (imgAbove(item) || imgOnly(item) || imgLeft(item))"
      >
        <div class="responsiveHeight posRelative" :class="{ max250: maxHeight == 250 }">
          <ObjektKarte
            :selectedobjects="kampagne.objekte"
            :icon="getUrlForImage(kampagne.objekticon, 0)"
            :clickInteraction="true"
          ></ObjektKarte></div
      ></v-col>

      <v-col
        v-if="modulanzeigen(item, 'K') && txtControls(item)"
        :class="{
          'px-5 posRelative noOverflow': modus == 'single',
          'mt-10': modus != 'single',
          'pl-5': modus != 'single' && imgLeft(item),
          'pr-5': modus != 'single' && imgRight(item)
        }"
        class="moduleContent"
      >
        <div
          v-html="modulText(item)"
          :class="{
            'px-5 responsiveHeight': modus == 'single',
            max250: maxHeight == 250,
            'pa-5': modus == 'single' && !imgLeft(item) && !imgRight(item)
          }"
        />
        <div v-if="modus == 'single'" class="textWhiteout" />
      </v-col>

      <v-col
        :cols="imgRight(item) ? imgWidth(item) : 12"
        class="zindex1"
        :class="{ 'mt-10': modus != 'single' }"
        v-if="modulanzeigen(item, 'K') && (imgBelow(item) || imgRight(item))"
      >
        <div class="responsiveHeight posRelative" :class="{ max250: maxHeight == 250 }">
          <ObjektKarte
            :selectedobjects="kampagne.objekte"
            :icon="getUrlForImage(kampagne.objekticon, 0)"
            :clickInteraction="true"
          ></ObjektKarte></div
      ></v-col>
    </v-row>
  </div>
</template>

<script>
import Kampagnen from '@/models/kampagnen';
import ObjektKarte from '@/components/map/objektKarte.vue';
import { getUrlForImage } from '@/utils/utils';

export default {
  data: () => ({
    kampagne: null,
    reihenfolge: ['K', '1', '2', '3', '4', '5', 'V', 'N', 'D', '6'],
    s3Base: import.meta.env.VITE_S3_BASE
  }),
  components: {
    ObjektKarte
  },
  props: {
    modulID: {
      type: String,
      default: () => undefined
    },
    modus: {
      type: String,
      default: () => 'all'
    },
    modKey: {
      type: String,
      default: () => '1'
    },
    maxHeight: {
      type: String,
      default: () => '0'
    }
  },
  computed: {
    compMaxHeight() {
      return Number(this.maxHeight) > 0
        ? 'overflow: hidden; max-height: ' + this.maxHeight + 'px;'
        : 'max-height: auto;';
    }
  },
  created() {
    this.kampagne = Kampagnen.find(this.modulID);
    this.reihenfolge = this.kampagne && this.modus == 'all' ? this.kampagne.modulsort : [this.modKey];
  },
  beforeDestroy() {},
  methods: {
    getUrlForImage,
    modulText(key) {
      let text = '';
      switch (key) {
        case 'K':
          text = this.kampagne.textdetail;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          text = this.kampagne['modul' + key + 'text'];
          break;
        case 'V':
          text = this.kampagne['veranstaltungentext'];
          break;
        case 'N':
          text = this.kampagne['newstext'];
          break;
      }
      return text;
    },
    imgurl(key) {
      if (this.kampagne['modul' + key + 'bild'] != '') {
        return `${import.meta.env.VITE_S3_BASE}/bilder/1920/${this.kampagne['modul' + key + 'bild']}`;
      } else {
        return '/img/dummyJPG.jpg';
      }
    },
    modulanzeigen(key, section) {
      let anzeigen = false;
      switch (key) {
        case 'K':
          anzeigen = section == 'K' && this.kampagne.modul0anzeige;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          anzeigen = section == 'M' && this.kampagne['modul' + key + 'anzeige'];
          break;
        case 'V':
          anzeigen = section == 'V' && this.kampagne['veranstaltungenanzeige'];
          break;
        case 'N':
          anzeigen = section == 'N' && this.kampagne['newsanzeige'];
          break;
        case 'D':
          anzeigen = section == 'D' && this.kampagne['downloadsanzeige'];
          break;
      }
      return anzeigen;
    },
    imgAbove(key) {
      let returnVal = false;
      switch (key) {
        case 'K':
          returnVal = this.kampagne.modul0textposition == 'unten' ? true : false;
          break;
      }
      return returnVal;
    },
    imgOnly(key) {
      let returnVal = false;
      switch (key) {
        case 'K':
          returnVal = this.kampagne.modul0textposition == 'karte' ? true : false;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          returnVal = this.kampagne['modul' + key + 'typ'] == 'bild' ? true : false;
      }
      return returnVal;
    },
    imgBelow(key) {
      switch (key) {
        case 'K':
          return this.kampagne.modul0textposition == 'oben' ? true : false;
      }
    },
    imgLeft(key) {
      let returnVal = true;
      switch (key) {
        case 'K':
          returnVal = this.kampagne.modul0textposition == 'rechts' ? true : false;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          returnVal = this.kampagne['modul' + key + 'typ'] == 'bildtext' ? true : false;
      }

      return returnVal;
    },
    imgRight(key) {
      let returnVal = false;
      switch (key) {
        case 'K':
          returnVal = this.kampagne.modul0textposition == 'links' ? true : false;
          break;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          returnVal = this.kampagne['modul' + key + 'typ'] == 'textbild' ? true : false;
      }
      return returnVal;
    },
    hasVideo(key) {
      switch (key) {
        case 'K':
          return false;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          return (
            this.kampagne &&
            this.kampagne['modul' + key + 'typ'] &&
            this.kampagne['modul' + key + 'typ'].indexOf('video') > -1
          );
      }
    },
    txtControls(key) {
      switch (key) {
        case 'K':
          return this.kampagne && this.kampagne.modul0textposition != 'karte' ? true : false;
        case '1':
        case '2':
        case '3':
        case '4':
        case '5':
        case '6':
          return (
            this.kampagne &&
            this.kampagne['modul' + key + 'typ'] &&
            this.kampagne['modul' + key + 'typ'].indexOf('text') > -1
          );
      }
    },
    imgWidth(key) {
      let breite = this.$vuetify.breakpoint.xsOnly ? 12 : 6;
      if (!this.$vuetify.breakpoint.smAndDown) {
        switch (key) {
          case 'K':
            breite = (3 - Number(this.kampagne.modul0bildbreite)) * 3;
            break;
          case '1':
          case '2':
          case '3':
          case '4':
          case '5':
          case '6':
            breite = (3 - Number(this.kampagne['modul' + key + 'bildbreite'])) * 3;
            break;
        }
      }
      return breite;
    }
  },
  watch: {}
};
</script>

<style>
.moduleContent p,
.moduleContent ul,
.moduleContent li,
.moduleContent a {
  font-size: 14px;
  margin: 0px;
  margin-bottom: 5px;
}

.moduleContent ul {
  padding-left: 14px;
}

.moduleContent li {
  list-style: '+ ';
}

.moduleContent li::marker {
  color: rgb(0, 115, 181);
  font-weight: bolder;
  font-size: 16px;
}

.moduleContent h1 {
  color: rgb(0, 115, 181);
  font-weight: bold;
  font-size: 22px;
  margin: 0px;
  margin-bottom: 10px;
  padding-top: 10px;
}

.moduleContent h2 {
  color: rgb(100, 100, 100);
  font-weight: bold;
  font-size: 20px;
  margin: 0px;
  margin-bottom: 8px;
}

.moduleContent h3 {
  display: inline-block;
  width: auto;
  background-color: rgb(0, 115, 181);
  color: rgb(255, 213, 0);
  padding: 4px;
  font-weight: bold;
  font-size: 22px;
  margin: 0px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
.moduleContent h4 {
  display: inline-block;
  width: auto;
  color: rgb(0, 115, 181);
  background-color: rgb(255, 213, 0);
  padding: 4px;
  font-weight: bold;
  font-size: 22px;
  margin: 0px;
  margin-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.moduleContent.veranstaltung {
  padding: 10px;
  background-color: rgb(0, 115, 181);
  color: #fff;
}
.moduleContent.veranstaltung h1,
.moduleContent.veranstaltung a {
  color: #fff;
}
.moduleContent.news {
  padding: 10px;
  background-color: rgb(255, 213, 0);
  color: rgb(0, 115, 181);
}
.moduleContent.news h1,
.moduleContent.news a {
  color: rgb(0, 115, 181);
}
.moduleContent.downloads {
  padding: 10px;
  color: rgb(0, 115, 181);
}
.moduleContent.downloads h1,
.moduleContent.downloads a {
  color: rgb(0, 115, 181);
  text-decoration: none;
}
.moduleContent.downloads a:hover span {
  text-decoration: underline;
}
</style>

<style scoped>
.posRelative {
  position: relative;
}

.whiteBack {
  background: none;
  background-color: #fff;
}

.textWhiteout {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: 50px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 5;
}

.noOverflow {
  overflow: hidden;
}
</style>
