<template>
  <v-card>
    <v-row no-gutters class="dark pa-2">
      <v-col cols="6"
        ><v-icon class="mr-2 mb-1" color="white">mdi-account-cog</v-icon>
        <strong>{{ type === 'verify' ? $t('label.verification') : $t('label.manageAccount') }}</strong></v-col
      >
      <v-col cols="6" align="right" v-if="type !== 'verify'"
        ><v-icon @click="closeEditor()" color="white">mdi-close</v-icon></v-col
      >
    </v-row>
    <div class="maxCard" ref="formContainer">
      <v-row no-gutters class="dark grey pa-2">
        <v-col cols="6"
          ><v-icon class="mr-2 mb-1" color="white">mdi-account</v-icon>
          <strong>{{ $t('label.profile') }}</strong></v-col
        >
        <v-col cols="6" align="right" class="percentageBar"
          ><span class="mr-2 mt-1">{{ completionPercent }} %</span>
          <div class="mt-1">
            <div :style="'width:' + completionPercent + '%;'"></div></div
        ></v-col>
      </v-row>
      <v-row no-gutters class="pa-2 px-3 subHeader">
        <v-col cols="12">
          {{ $t('label.requiredHint') }}
        </v-col></v-row
      >

      <v-form ref="formbasic">
        <v-row no-gutters class="pa-2 px-5">
          <v-col cols="12">
            <v-text-field
              v-model="benutzerData.vorname"
              :label="$t('label.firstname')"
              outlined
              dense
              hide-details
              class="pa-1"
              filled
              background-color="rgba(100, 190, 254, .1)"
              :rules="[rules.required]"
              @change="percentageCalc"
            />
            <v-text-field
              v-model="benutzerData.nachname"
              :label="$t('label.lastname')"
              outlined
              dense
              hide-details
              class="pa-1"
              filled
              background-color="rgba(100, 190, 254, .1)"
              :rules="[rules.required]"
              @change="percentageCalc"
            />
            <v-text-field
              v-model="benutzerData.firma"
              :label="$t('label.companyname')"
              outlined
              dense
              hide-details
              class="pa-1"
            />
            <v-text-field
              v-model="benutzerData.adresse1"
              :label="$t('label.address')"
              outlined
              dense
              hide-details
              class="mt-6 pa-1"
              filled
              background-color="rgba(100, 190, 254, .1)"
              :rules="[rules.required]"
              @change="percentageCalc"
            />
            <v-text-field
              v-model="benutzerData.adresse2"
              :label="`${$t('label.address')} (2)`"
              outlined
              dense
              hide-details
              class="pa-1"
            />
            <v-row no-gutters>
              <v-col cols="4">
                <v-text-field
                  v-model="benutzerData.plz"
                  :label="$t('label.areacode')"
                  outlined
                  dense
                  hide-details
                  filled
                  background-color="rgba(100, 190, 254, .1)"
                  :rules="[rules.required]"
                  @change="percentageCalc"
                  class="pa-1"
                /> </v-col
              ><v-col cols="8">
                <v-text-field
                  v-model="benutzerData.ort"
                  :label="$t('label.city')"
                  outlined
                  dense
                  hide-details
                  filled
                  background-color="rgba(100, 190, 254, .1)"
                  :rules="[rules.required]"
                  @change="percentageCalc"
                  class="pa-1"
                /> </v-col
            ></v-row>
            <v-text-field
              v-model="benutzerData.telefon"
              :label="$t('label.phone')"
              outlined
              dense
              hide-details
              filled
              background-color="rgba(100, 190, 254, .1)"
              :rules="[rules.required]"
              @change="percentageCalc"
              class="mt-6 pa-1"
            />
            <v-text-field
              v-model="benutzerData.mobil"
              :label="$t('label.mobile')"
              outlined
              dense
              hide-details
              class="pa-1"
            />

            <v-row no-gutters class="mt-3 pa-2 grey lighten-4" v-if="newUser">
              <h3>E-Mail / Passwort:</h3>
              <v-col cols="12">
                <v-text-field
                  background-color="white"
                  v-model="benutzerData.email"
                  :label="$t('label.email')"
                  outlined
                  dense
                  hide-details
                  class="pa-1"
                  :rules="[rules.email, rules.required]"
                  autocomplete="off"
                />
                <v-text-field
                  background-color="white"
                  v-model="benutzerData.passwort"
                  :rules="[rules.passwordCreate]"
                  label="Passwort"
                  outlined
                  dense
                  hide-details
                  class="pa-1"
                  autocomplete="new-password"
                /> </v-col
            ></v-row>
          </v-col>
          <div class="separator mt-5" />
          <v-col cols="12">
            <div class="infoCheckbox">
              <v-checkbox dense hide-details :label="$t('label.newsletter')" v-model="benutzerData.newsletter" />
            </div>
            <v-icon class="ml-2 mb-2 infoIcon" @click="showInfo = !showInfo">mdi-information</v-icon></v-col
          >
          <v-col cols="12" class="newsletterRemark pa-3" v-show="showInfo">
            <h4>* {{ $t('label.consentInformationHeader') }}</h4>
            <div v-html="$t('label.consentInformation')" />
          </v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4"
            ><v-checkbox
              dense
              hide-details
              :label="$t('label.interestedInStandortsuche')"
              v-model="interesseStandortsuche"
            /> </v-col
          ><v-col :cols="$vuetify.breakpoint.xs ? 12 : 4">
            <v-checkbox dense hide-details :label="$t('label.interestedInFoerderung')" v-model="interesseFoerderung"
          /></v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 12 : 4">
            <v-checkbox dense hide-details :label="$t('label.iAmProprietor')" v-model="benutzerData.objektinhaber"
          /></v-col>
          <div class="separator mt-5" />
        </v-row>
        <v-row no-gutters>
          <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'pa-1' : 'pa-5'"
            ><v-btn @click="closeEditor" v-if="type !== 'verify'" color="grey darken-1" block dark>
              <v-icon class="mr-2">mdi-close</v-icon>{{ $t('button.cancel') }}</v-btn
            ></v-col
          >
          <v-col cols="6" :class="$vuetify.breakpoint.xs ? 'pa-1' : 'pa-5'"
            ><v-btn @click="saveUser" color="rgb(0, 115, 181)" block dark
              ><v-icon class="mr-2">mdi-check</v-icon>{{ $t('button.save') }}</v-btn
            ></v-col
          >
        </v-row>
      </v-form>

      <v-form ref="formemail" v-if="type !== 'verify' && !newUser">
        <v-row no-gutters class="dark grey pa-2">
          <v-col cols="12"
            ><v-icon class="mr-2 mb-1" color="white">mdi-email</v-icon>
            <strong>{{ $t('label.changeEmail') }}</strong></v-col
          >
        </v-row>
        <v-row no-gutters class="pa-2 px-5">
          <v-col cols="12">
            <v-text-field
              v-model="benutzerData.email"
              disabled
              :label="$t('label.email')"
              outlined
              dense
              hide-details
              class="pa-1"
              :rules="[rules.email]"
            />
            <v-text-field
              v-model="emailNeu"
              :label="$t('label.newEmail')"
              outlined
              dense
              hide-details
              class="pa-1"
              :rules="[rules.email]"
            /> </v-col
        ></v-row>
        <v-row no-gutters class="pa-2">
          <v-col :cols="$vuetify.breakpoint.xs ? 4 : 8" class="pa-2"></v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 8 : 4" class="pa-2 px-4"
            ><v-btn
              @click="changeEmail"
              color="green"
              style="color: white"
              block
              :disabled="!emailNeu || rules.email(emailNeu) !== true"
              ><v-icon class="mr-2">mdi-check</v-icon>{{ $t('button.change') }}</v-btn
            ></v-col
          >
        </v-row>
      </v-form>

      <v-form ref="formpassword" v-if="type !== 'verify' && !newUser">
        <v-row no-gutters class="dark grey pa-2">
          <v-col cols="12"
            ><v-icon class="mr-2 mb-1" color="white">mdi-lock</v-icon>
            <strong>{{ $t('label.password.changePassword') }}</strong></v-col
          >
        </v-row>
        <v-row no-gutters class="pa-2 px-5">
          <v-col cols="12">
            <v-text-field
              @keyup="pwQuality"
              v-model="passwortNeu"
              :rules="[rules.password]"
              :label="$t('label.password.newPassword')"
              outlined
              dense
              hide-details
              class="pa-1"
              :append-icon="showNewPw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPw ? 'text' : 'password'"
              @click:append="showNewPw = !showNewPw"
            />
            <div v-if="passwortNeu.length" class="pwIndicator pa-2">
              <div :style="'background-color:' + pwQualityVal.color + ';'">
                {{ $t('label.password.quality') }}: {{ $t('label.password.' + pwQualityVal.description) }}
              </div>

              <span>{{ $t('label.password.hint') }}</span>
            </div>

            <v-text-field
              v-model="passwortNeuRepeat"
              :label="$t('label.password.repeatNewPassword')"
              :rules="[rules.passwortNeuRepeat]"
              outlined
              dense
              hide-details
              class="pa-1"
              :append-icon="showNewPwRepeat ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showNewPwRepeat ? 'text' : 'password'"
              @click:append="showNewPwRepeat = !showNewPwRepeat"
            />
            <v-text-field
              v-if="!this.$route.meta.requiresAuth"
              v-model="passwortAlt"
              :label="$t('label.password.confirmOldPassword')"
              outlined
              dense
              hide-details
              class="pa-1"
              :append-icon="showOldPw ? 'mdi-eye' : 'mdi-eye-off'"
              :type="showOldPw ? 'text' : 'password'"
              @click:append="showOldPw = !showOldPw"
            /> </v-col
        ></v-row>
        <v-row no-gutters class="pa-2">
          <v-col :cols="$vuetify.breakpoint.xs ? 4 : 8" class="pa-2"></v-col>
          <v-col :cols="$vuetify.breakpoint.xs ? 8 : 4" class="pa-2 px-4"
            ><v-btn @click="changePassword" color="green" block style="color: white" :disabled="changePasswordDisabled"
              ><v-icon class="mr-2">mdi-check</v-icon>{{ $t('button.change') }}</v-btn
            ></v-col
          >
        </v-row>
      </v-form>
      <div class="separator my-3" />
      <v-row no-gutters class="pa-5">
        <v-col cols="12" v-html="$t('label.eraseaccount')" />
      </v-row>
    </div>
  </v-card>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import Axios from 'axios';
import Benutzer from '@/models/benutzer';

export default {
  props: {
    benutzerId: {
      type: Number,
      default: () => null
    },
    type: {
      type: String,
      default: () => ''
    }
  },
  data: () => ({
    newUser: false,
    pwQualityVal: { color: 'red', description: 'tooWeak' },
    emailNeu: '',
    passwortAlt: '',
    passwortNeu: '',
    passwortNeuRepeat: '',
    showInfo: false,
    showNewPw: false,
    showNewPwRepeat: false,
    showOldPw: false,
    completionPercent: 0,
    rules: {
      required: value => !!value || 'Dieses Feld ist ein Pflichtfeld',
      plz: value => (value >= 1000 && value <= 9999) || 'PLZ muss vierstellig sein.',
      passwortNeuRepeat: () => true,
      password: () => true,
      passwordCreate: value => value.length > 5 || 'Zu kurz',
      email: value => {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || !value || value.length === 0 || 'Ungültige Email.';
      }
    },
    interesseStandortsuche: false,
    interesseFoerderung: false,
    benutzerData: {}
  }),
  computed: {
    changePasswordDisabled() {
      return (
        !this.passwortNeuRepeat ||
        this.rules.passwortNeuRepeat(this.passwortNeuRepeat) !== true ||
        this.rules.password(this.passwortNeu) !== true
      );
    }
  },
  created() {
    //@ts-ignore
    if (!this.benutzerId) {
      const rightNow = new Date().toISOString().slice(0, 19).replace('T', ' ');
      for (const field in Benutzer.fields()) {
        //@ts-ignore
        this.benutzerData[field] =
          typeof Benutzer.fields()[field].value != 'undefined' ? Benutzer.fields()[field].value : rightNow;
      }
      //@ts-ignore
      this.newUser = true;
    } else {
      //@ts-ignore
      Axios.get('/api/benutzer/' + this.benutzerId).then(async result => {
        //@ts-ignore
        this.benutzerData = await result.data;
        //@ts-ignore
        this.interesseStandortsuche =
          //@ts-ignore
          this.benutzerData && this.benutzerData.interessen && this.benutzerData.interessen.includes('standortsuche');
        //@ts-ignore
        this.interesseFoerderung =
          //@ts-ignore
          this.benutzerData && this.benutzerData.interessen && this.benutzerData.interessen.includes('foerderungen');
        //@ts-ignore
        this.percentageCalc();
      });
    }
    //@ts-ignore
    this.rules.passwortNeuRepeat = value => {
      //@ts-ignore
      return value === this.passwortNeu || this.$t('error.passwordWeak');
    };
    //@ts-ignore
    this.rules.password = () => {
      //@ts-ignore
      return this.pwQuality() > 0 || this.$t('error.passwordWeak');
    };
  },
  mounted() {},
  methods: {
    pwQuality() {
      let rank = 0;
      const rankstr = ['tooWeak', 'weak', 'acceptable', 'good', 'strong', 'excellent'];
      const colors = ['red', 'orange', 'goldenrod', 'darkcyan', 'mediumseagreen', 'limegreen'];
      const uniqueCount = new Set(this.passwortNeu).size;

      if (uniqueCount > 7) {
        rank++;
      }

      if (this.passwortNeu.length > 7) {
        rank++;
      }

      if (this.passwortNeu.toLowerCase() != this.passwortNeu) {
        rank++;
      }
      if (this.passwortNeu.match(/\W/)) {
        rank++;
      }
      if (this.passwortNeu.match(/\d/)) {
        rank++;
      }
      this.pwQualityVal = { wert: rank, color: colors[rank], description: rankstr[rank] };
      return rank;
    },
    /**
     * changes ONLY email of the user
     */
    async changeEmail() {
      try {
        if (this.rules.email(this.emailNeu) !== true) {
          return await this.$root.$confirm({
            title: `${this.$t('error.savingError')}`,
            message: `${this.$t('error.invalidmail')}`
          });
        }
        const existingUser = await Axios.get('/api/benutzer/' + this.benutzerId);
        const payload = await existingUser.data;
        payload.email = this.emailNeu;
        this.deleteForbiddenKeys(payload);
        await Axios.put('/api/benutzer/' + this.benutzerId, payload);
        await this.$root.$confirm({
          title: `${this.$t('label.changesSavedHeader')}`,
          message: `${this.$t('label.emailChanged')}`,
          appearance: 'success'
        });
        this.benutzerData.email = this.emailNeu;
        this.emailNeu = '';
      } catch (e) {
        await this.$root.$confirm({
          title: `${this.$t('error.savingError')}`,
          message: this.getErrorMessage(e)
        });
      }
    },
    async changePassword() {
      if (!this.$route.meta.requiresAuth) {
        try {
          await Axios.post('/api/passwordcheck', { password: this.passwortAlt });
        } catch (e) {
          return await this.$root.$confirm({
            title: `${this.$t('error.savingError')}`,
            message: `${this.$t('error.oldPasswordIncorrect')}`
          });
        }
      }
      try {
        const existingUser = await Axios.get('/api/benutzer/' + this.benutzerId);
        const payload = await existingUser.data;
        payload.passwort = this.passwortNeu;
        this.deleteForbiddenKeys(payload);
        await Axios.put('/api/benutzer/' + this.benutzerId, payload);
        await this.$root.$confirm({
          title: `${this.$t('label.changesSavedHeader')}`,
          message: `${this.$t('label.emailChanged')}`,
          appearance: 'success'
        });
        this.passwortNeu = '';
        this.passwortNeuRepeat = '';
        this.passwortAlt = '';
      } catch (e) {
        await this.$root.$confirm({
          title: `${this.$t('error.savingError')}`,
          message: this.getErrorMessage(e)
        });
      }
    },
    percentageCalc() {
      const checklist = ['vorname', 'nachname', 'adresse1', 'plz', 'ort', 'telefon'];
      let okCount = 0;
      checklist.forEach(check => {
        if (this.benutzerData[check] && this.benutzerData[check].length > 1) {
          okCount++;
        }
      });

      this.completionPercent = Math.floor(okCount * (100 / 6));
    },
    getValidation() {
      // @ts-ignore
      return this.$refs.form.validate();
    },
    /**
     * changes user with the current values EXCEPT email
     */
    async saveUser() {
      // @ts-ignore
      if (this.$refs.formbasic.validate()) {
        const interessen = [];
        if (this.interesseStandortsuche) interessen.push('standortsuche');
        if (this.interesseFoerderung) interessen.push('foerderungen');
        this.benutzerData.interessen = interessen.join(',');
        const payload = this.benutzerData;
        payload.profil = this.completionPercent;
        try {
          if (this.benutzerId) {
            const existingUser = await Axios.get('/api/benutzer/' + this.benutzerId);
            const existingUserData = await existingUser.data;
            payload.email = existingUserData.email;
          }
          this.deleteForbiddenKeys(payload);
          if (this.newUser) {
            delete payload['id'];
            payload.status = 1;
            payload.maillevel = 0;
            payload.userlevel = 0;
            payload.nologging = 0;
            const response = await Axios.post('/api/benutzer/', payload);
            payload.id = response.data.id;
          } else {
            await Axios.put('/api/benutzer/' + this.benutzerId, payload);
          }
          // get updated userdata and update values in the store
          if (!this.$route.meta.requiresAuth) {
            const response = await Axios.get('/api/userdata');
            this.$store.dispatch('userdata', response.data);
          } else {
            this.$emit('benutzerUpdate', this.benutzerId, payload);
          }
        } catch (e) {
          await this.$root.$confirm({
            title: `${this.$t('error.savingError')}`,
            message: this.getErrorMessage(e)
          });
        }
        this.closeEditor();
      } else {
        if (this.completionPercent !== 100) {
          await this.$root.$confirm({
            title: `${this.$t('error.profileIncompleteHeader')}`,
            message: `${this.$t('error.profileIncomplete1')}<br />${this.$t('error.profileIncomplete2')}`
          });
        }
        // validation error
        this.$nextTick(() => {
          const el = this.$el.querySelector('.error--text:first-of-type');
          this.$vuetify.goTo(el, {
            container: this.$refs.formContainer,
            duration: 400,
            offset: 200,
            easing: 'easeInOutCubic'
          });
          return;
        });
      }
    },
    closeEditor() {
      this.$emit('editorClose');
    },

    /**
     * deletes forbidden keys from a payload object.
     * changes the given object in place
     */
    deleteForbiddenKeys(payload) {
      ['userlevel', 'maillevel', 'nologging'].forEach(key => {
        delete payload[key];
      });
    },

    /**
     * get the most verbose error message from an request error object
     * @param {import("axios").AxiosError} e
     * @returns {string} error message string
     */
    getErrorMessage(e) {
      let message;
      if (e.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        if (e.response.data && e.response?.data.errors?.length && e.response?.data.errors[0].message) {
          const responseMessage = e.response?.data.errors[0].message;
          message = responseMessage === 'email must be unique' ? this.$t('error.uniqueEmail') : responseMessage;
        } else {
          message = e.response.data;
        }
      } else if (e.request) {
        // The request was made but no response was received
        message = this.$t('error.connectionError');
      } else {
        // Something happened in setting up the request that triggered an Error
        message = e.message || e.stack || e.response?.status || e;
      }
      return message;
    }
  }
};
</script>

<style scoped>
.maxCard {
  max-height: calc((var(--vh, 1vh) * 100) - 200px);
  overflow: auto;
}
.separator {
  width: 100%;
  height: 1px;
  border-top: 1px solid #ddd;
}
.subHeader {
  background-color: #ddd;
}
.dark {
  background-color: rgb(0, 115, 181);
  color: white;
  font-size: 18px;
}
.dark.grey {
  background-color: #666;
  font-size: 16px;
}

.newsletterRemark {
  border: 1px solid #ddd;
  font-size: 12px;
}

.percentageBar div {
  display: inline-flex;
  width: 100px;
  border: 1px solid white;
  height: 20px;
  padding: 1px;
}
.percentageBar div div {
  height: 100%;
  background-color: white;
  border: none;
}

.infoCheckbox {
  max-width: 700px;
  display: inline-flex;
}

.infoIcon {
  display: inline-flex;
}

.pwIndicator div {
  width: 100%;
  height: 25px;
  line-height: 25px;
  color: #fff;
  text-align: center;
}
.pwIndicator span {
  font-size: 12px;
}
</style>
