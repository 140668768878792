<template>
  <div>
    <v-carousel
      v-if="!this.$vuetify.breakpoint.xsOnly"
      class="responsiveHeight withControls thinBlueBorder"
      :show-arrows="false"
      interval="15000"
      cycle
    >
      <v-carousel-item :to="'wissen/' + item.id" v-for="(item, i) in titelKampagnen" :key="i">
        <div class="carouselItem">
          <kampagneVorschau
            :modulID="item.id"
            modus="single"
            :modKey="item.modultitel"
            maxHeight="400"
          ></kampagneVorschau>
        </div>
      </v-carousel-item>
    </v-carousel>
    <div v-if="this.$vuetify.breakpoint.xsOnly">
      <v-card v-if="$route.params.lang == 'en'" class="pa-4" align="center"
        >Important note: Content in the know how section is currently only available in German language!</v-card
      >

      <v-row no-gutters>
        <v-col :cols="$vuetify.breakpoint.smAndDown ? 12 : 6" class="pa-3" v-for="(item, i) in titelKampagnen" :key="i">
          <v-card
            :class="{ wissenCard: !$vuetify.breakpoint.xsOnly }"
            :to="'/' + $route.params.lang + '/wissen/' + item.id"
          >
            <kampagneVorschau
              :modulID="item.id"
              modus="single"
              :modKey="item.modulwissen"
              :maxHeight="$vuetify.breakpoint.xsOnly ? '500' : '250'"
            ></kampagneVorschau>
          </v-card>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import Kampagnen from '@/models/kampagnen';
import kampagneVorschau from '@/components/kampagneVorschau.vue';

/**
 * Objekt Details
 */
export default {
  components: {
    kampagneVorschau
  },
  props: {},

  data() {
    return {};
  },
  computed: {
    titelKampagnen() {
      return Kampagnen.query().where('titelseite', true).orderBy('reihenfolge', 'desc').get();
    }
  },
  methods: {}
};
</script>

<style>
.carouselItem {
  background-color: white;
  height: 400px;
  max-height: 400px;
  overflow: hidden;
}

.v-carousel__controls {
  height: 40px;
  background-color: rgba(0, 115, 181, 1);
}
</style>
