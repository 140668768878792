<template>
  <div class="d-flex align-center justify-center pa-4">
    <OverlayScroller :switch="overlayTrigger" :html="overlayHtml" />
    <v-card width="480px" elevation="4">
      <div v-if="error" class="error pa-5">
        {{ error }}
      </div>
      <div class="pa-5">
        <!-- Login Form -->
        <v-form @submit.prevent="submit" v-model="isValid" v-if="loginMode">
          <v-text-field
            ref="focusMe"
            v-model="username"
            :label="$t('label.usernameEqualsEmail')"
            placeholder="Benutzername"
            single
            outlined
            required
            autocomplete="username"
          />
          <v-text-field
            v-model="password"
            :label="$t('label.password.password')"
            placeholder="Passwort"
            required
            single
            outlined
            type="password"
            autocomplete="current-password"
          />

          <v-row no-gutters>
            <v-col cols="4">
              <v-btn color="rgb(255, 213, 0)" to="home" block>
                {{ $t('button.cancel') }}
              </v-btn></v-col
            >
            <v-col cols="4"></v-col>
            <v-col cols="4">
              <v-btn type="submit" color="rgb(255, 213, 0)" block :disabled="!isValid">
                {{ $t('button.login') }}
              </v-btn></v-col
            ></v-row
          >
          <v-card @click="resendPassword" class="mt-4 pa-2 font-weight-light smallText">
            {{ $t('label.password.forgotPassword') }}
          </v-card>
        </v-form>

        <!-- Registration Form -->
        <v-form @submit.prevent="register" ref="form" v-model="isValid" v-else>
          <v-text-field
            ref="focusMe"
            v-model="username"
            class="mb-4"
            hide-details
            :label="$t('label.usernameEqualsEmail')"
            :placeholder="$t('label.usernameEqualsEmail')"
            :rules="[rules.email]"
            :disabled="waitingForEmail"
            single
            outlined
            required
          />
          <v-text-field
            v-model="password"
            :label="$t('label.password.password')"
            :placeholder="$t('label.password.password')"
            :rules="[rules.password]"
            :disabled="waitingForEmail"
            :append-icon="showPw ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPw ? 'text' : 'password'"
            @click:append="showPw = !showPw"
            hide-details
            required
            single
            outlined
          />
          <div class="pwIndicator pa-2">
            <div v-if="password.length" :style="'background-color:' + pwQualityVal.color + ';'">
              {{ $t('label.password.quality') }}: {{ $t('label.password.' + pwQualityVal.description) }}
            </div>

            <span>{{ $t('label.password.hint') }}</span>
          </div>
          <v-text-field
            v-model="passwordRepeat"
            :label="$t('label.password.repeatPassword')"
            class="mb-0"
            :placeholder="$t('label.password.password')"
            :disabled="waitingForEmail"
            :append-icon="showPwRepeat ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showPwRepeat ? 'text' : 'password'"
            @click:append="showPwRepeat = !showPwRepeat"
            :rules="[rules.passwordRepeat]"
            required
            single
            outlined
          />
          <div
            style="width: 100%"
            class="d-flex justify-center mb-3 pa-1"
            :class="recaptchaVerified !== false || 'redBorder'"
          >
            <vue-recaptcha
              :sitekey="sitekey"
              :loadRecaptchaScript="true"
              @verify="onRecaptchaVerify"
              @expired="onRecaptchaError"
              @error="onRecaptchaError"
              :rules="[rules.passwordRepeat, rules.required]"
            />
          </div>

          <div class="d-flex align-center">
            <v-checkbox
              class="mt-0 pt-0"
              dense
              hide-details
              required
              :disabled="waitingForEmail"
              :rules="[rules.required]"
              v-model="agb"
            />
            <span @click="showOverlay('terms')" class="smallText" v-html="$t('label.consentTerms')"></span>
          </div>
          <div class="d-flex align-center">
            <v-checkbox class="mt-0 pt-0" dense hide-details :disabled="waitingForEmail" v-model="newsletter" />
            <span class="smallText" v-html="$t('label.consentNewsletter')"></span>
          </div>
          <v-btn
            type="submit"
            :loading="waitingForEmail"
            :disabled="waitingForEmail"
            color="rgb(255, 213, 0)"
            block
            class="mt-2"
          >
            {{ $t('button.register') }}
          </v-btn>
        </v-form>
      </div>
      <v-btn
        class="registerButton"
        color="rgb(255, 213, 0)"
        :disabled="waitingForEmail"
        @click="loginMode = !loginMode"
      >
        {{ loginMode ? $t('button.goToRegister') : $t('button.goToLogin') }}
      </v-btn>
    </v-card>
  </div>
</template>
<script>
import axios from 'axios';
import VueRecaptcha from 'vue-recaptcha';
import OverlayScroller from '@/components/misc/OverlayScroller.vue';
import { loader } from '@/mixins/loader';

export default {
  name: 'Login',
  mixins: [loader],
  data() {
    return {
      overlayHtml: '',
      overlayTrigger: false,
      username: '',
      password: '',
      passwordRepeat: '',
      isValid: true,
      error: null,
      loginMode: true,
      agb: false,
      newsletter: false,
      sitekey: import.meta.env.VITE_RECAPTCHA_KEY,
      recaptchaVerified: null,
      showPw: false,
      showPwRepeat: false,
      waitingForEmail: false,
      pwQualityVal: { color: 'red', description: 'tooWeak' },
      rules: {
        passwordRepeat: () => true,
        password: () => true,
        required: value => !!value || 'Dieses Feld ist ein Pflichtfeld',
        email: value => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || !value || value.length === 0 || 'Ungültige Email.';
        }
      }
    };
  },
  components: {
    // @ts-ignore
    VueRecaptcha,
    OverlayScroller
  },
  created() {
    //@ts-ignore
    this.rules.passwordRepeat = value => {
      //@ts-ignore
      return value === this.password || this.$t('label.password.notIdentical');
    };
    //@ts-ignore
    this.rules.password = () => {
      //@ts-ignore
      return this.pwQuality() > 0 || this.$t('error.passwordWeak');
    };
  },
  mounted() {
    /** @type {HTMLInputElement} */ (this.$refs.focusMe).focus();
  },
  methods: {
    showOverlay(content) {
      this.overlayHtml = content;
      this.overlayTrigger = !this.overlayTrigger;
    },
    getValidation() {
      // @ts-ignore
      return this.$refs.form.validate();
    },
    submit() {
      axios
        .post('/api/login', {
          username: this.username,
          password: this.password
        })
        .then(async userdata => {
          /**
           * set userdata to store before fetching all additional data (e.g. contact, which is depenging on userdata)
           * normally, userdata is fetched via the router
           */
          await this.$store.dispatch('userdata', userdata.data);
          await this.reLoad(this); // reload user-specific data (contact, objekte)
          this.$router.push({
            path: /** @type {string} */ (
              this.$route.query.next ? this.$route.query.next : `/${this.$route.params.lang}/home`
            )
          });
        })
        .catch(error => {
          const data = error.response.data;
          if (data && data.info) {
            const message = data.info.message;
            this.error = ['incorrectEmailOrPassword', 'userNotVerified'].includes(message)
              ? this.$t(`error.${message}`)
              : data.info.message;
          } else {
            this.error = error.message || error.stack || error.response?.status || error;
          }
        });
    },
    onRecaptchaVerify() {
      this.recaptchaVerified = true;
    },
    onRecaptchaError() {
      this.recaptchaVerified = false;
    },
    resendPassword() {
      if (!this.username) {
        this.$root.$confirm({
          title: `${this.$t('label.password.newPassword')}`,
          message: `${this.$t('error.missingEmail')}`
        });
        return;
      }
      axios
        .post('/api/passwordreset', { username: this.username })
        .then(() => {
          this.$root.$confirm({
            title: `${this.$t('label.password.newPassword')}`,
            message: `${this.$t('label.password.newPasswordSent')}`,
            appearance: 'success'
          });
        })
        .catch(error => {
          this.$root.$confirm({
            title: `${this.$t('label.password.newPassword')}`,
            message: error.response?.data
              ? `${error.response.data.message}`
              : `${this.$t('label.password.newPasswordError')}`
          });
        });
    },
    async register() {
      if (this.getValidation()) {
        if (!this.recaptchaVerified) {
          this.recaptchaVerified = false;
          return;
        }
        const newBenutzer = {
          email: this.username,
          passwort: this.password
        };
        try {
          this.waitingForEmail = true;
          await axios.post('/api/register', newBenutzer);
          this.waitingForEmail = false;
          await this.$root.$confirm({
            title: `Registrierung erfolgreich`,
            message: `Eine Email mit Registrierungslink ist auf dem Weg zu Ihnen.`,
            appearance: 'success'
          });
          this.$router.push({ name: 'home' });
        } catch (error) {
          this.waitingForEmail = false;
          this.$root.$confirm({
            title: `Fehler bei der Registrierung`,
            message: `Bei der Registrierung ist ein Fehler aufgetreten.
            Möglicherweise ist die gewählte Email-Adresse bereits vergeben.`
          });
        }
      }
    },
    pwQuality() {
      let rank = 0;
      const rankstr = ['tooWeak', 'weak', 'acceptable', 'good', 'strong', 'excellent'];
      const colors = ['red', 'orange', 'goldenrod', 'darkcyan', 'mediumseagreen', 'limegreen'];
      const uniqueCount = new Set(this.password).size;

      if (uniqueCount > 7) {
        rank++;
      }

      if (this.password.length > 7) {
        rank++;
      }

      if (this.password.toLowerCase() != this.password) {
        rank++;
      }
      if (this.password.match(/\W/)) {
        rank++;
      }
      if (this.password.match(/\d/)) {
        rank++;
      }
      this.pwQualityVal = { wert: rank, color: colors[rank], description: rankstr[rank] };
      return rank;
    }
  }
};
</script>

<style>
.smallText {
  font-size: 12px;
  user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  -webkit-touch-callout: none;
  -o-user-select: none;
  -moz-user-select: none;
}

.smallText u {
  color: rgb(0, 115, 181);
  cursor: pointer;
}
</style>
<style scoped>
.registerButton {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  width: 100%;
}

.redBorder {
  border: 2px solid #ff5252;
  border-radius: 4px;
}
</style>
