import { Style, Stroke, Icon, Fill } from 'ol/style';
import { objektFarben, switchResolution, objektIcons, fillColors } from './constants';
import CircleStyle from 'ol/style/Circle';
import { asArray } from 'ol/color';
import store from '@/store';
import {
  featureMeetsEcoplusOnlySelection,
  featureMeetsFavsOnlySelection,
  featureMeetsFlaecheSelection,
  featureMeetsKaufMietSelection,
  featureMeetsTagsSelection,
  featureMeetsPriceSelection,
  featureMeetsStatusSelection,
  featureMeetsOnlyPropertiesSelection
} from '@/utils/formatters';
import router from '@/router';

const outlineStyle = new Style({
  stroke: new Stroke({
    width: 3,
    color: 'fuchsia'
  }),
  fill: new Fill({
    color: 'fuchsia'
  })
});

const objektStyles = {};
for (const key in objektIcons) {
  objektStyles[key] = {};
  const url = objektIcons[key];
  objektStyles[key] = new Style({
    image: new Icon({
      anchor: [17, 1],
      anchorXUnits: 'pixels',
      src: `/img/icons/${url}`,
      crossOrigin: ''
    }),
    zIndex: 3
  });
}

export const blueAnchorPoint = new Style({
  image: new CircleStyle({
    radius: 5,
    fill: new Fill({
      color: 'rgb(0, 115, 181)'
    })
  }),
  zIndex: 5
});

const starStyle = new Style({
  image: new Icon({
    anchor: [-3, 60],
    anchorXUnits: 'pixels',
    anchorYUnits: 'pixels',
    src: `/img/icons/star.png`,
    crossOrigin: ''
  }),
  zIndex: 4
});

/**
 *
 * @param {*} feature
 * @param {number} resolution
 * @param {boolean} grey if true, a soft grey style will be used
 */
export function objektStyle(feature, resolution, grey) {
  const currentRoute = router.currentRoute;
  const isEditRoute = currentRoute.name === 'edit-objekt' || currentRoute.name === 'admin-objekt';
  if (isEditRoute) {
    // do not display other objekte when editing in "Meine Immobilien" or in admin
    if (currentRoute.params.objektId != feature.get('id')) {
      return;
    }
  }
  if (currentRoute.name === 'edit-objekt' || currentRoute.name === 'admin-objekt') {
    // do not display other objekte when editing in "Meine Immobilien" or in admin
    if (currentRoute.params.objektId != feature.get('id')) {
      return;
    }
  }

  const objektart = feature.get('objektart');
  if (!objektart || (store.state.selectedType && objektart !== store.state.selectedType)) {
    return;
  }
  if (!featureMeetsKaufMietSelection(feature)) {
    return;
  }
  if (!featureMeetsTagsSelection(feature)) {
    return;
  }
  if (!featureMeetsFlaecheSelection(feature)) {
    return;
  }
  if (!featureMeetsPriceSelection(feature)) {
    return;
  }
  if (!featureMeetsEcoplusOnlySelection(feature)) {
    return;
  }
  if (!featureMeetsStatusSelection(feature)) {
    return;
  }
  if (!featureMeetsFavsOnlySelection(feature)) {
    return;
  }

  if (!featureMeetsOnlyPropertiesSelection(feature)) {
    return;
  }

  const opacity = grey ? 0.35 : 1;
  const forcePolygon =
    (currentRoute.params.objektId == feature.get('id') && resolution < switchResolution) || isEditRoute;
  return getObjektStyle(feature, objektart, opacity, resolution, grey, forcePolygon);
}

/**
 * Separate function to get style, can be used to generate style whilte ignoring store variables
 * @param {*} feature
 * @param {*} objektart
 * @param {*} opacity
 * @param {*} resolution
 * @param {*} grey
 * @param {*} drawPolygon
 * @returns {Array<Style>}
 */
export function getObjektStyle(feature, objektart, opacity, resolution, grey, drawPolygon) {
  blueAnchorPoint.getImage().setOpacity(opacity);
  const style = feature.get('_hovered') ? [blueAnchorPoint] : [];
  const scale = getIconScaleForResolution(resolution);
  if (drawPolygon) {
    const fillColor = [...fillColors[objektart]];
    fillColor[3] = grey ? 0.1 : 0.2;
    outlineStyle.getFill().setColor(fillColor);
    const strokeColor = [...asArray(objektFarben[objektart])];
    strokeColor[3] = opacity;
    outlineStyle.getStroke().setColor(strokeColor);
    outlineStyle.setZIndex(grey ? 0 : 1); // always keep the editing objekt up front
    style.unshift(outlineStyle);
  }
  const iconStyle = objektStyles[objektart];
  const image = iconStyle.getImage();
  image.setScale(scale);
  image.setOpacity(opacity);
  style.unshift(iconStyle);

  const favs = store.state.userdata?.favoriten;
  if (favs && favs.includes(`objekt.${feature.getId()}`)) {
    const starImage = starStyle.getImage();
    starImage.setScale(scale);
    style.push(starStyle);
    iconStyle.setZIndex(4); // keep fav objects in front
  } else {
    iconStyle.setZIndex(3);
  }
  return style;
}

/**
 * some quick maffs to get somewhat nice icon scaling
 * @param {*} resolution
 * @returns
 */
function getIconScaleForResolution(resolution) {
  const reverseFactor = Math.cbrt(200 + resolution) / 5;
  const normalizedReverseFactor = reverseFactor < 1 ? 1 : reverseFactor > 2 ? 2 : reverseFactor;
  return (3 - normalizedReverseFactor) / 2;
}

export const vertexStyle = [
  new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'white',
        width: 3
      })
    })
  }),
  new Style({
    image: new CircleStyle({
      radius: 5,
      stroke: new Stroke({
        color: 'gray',
        width: 2
      })
    })
  })
];
